import { ref, computed, watch, reactive } from 'vue';
import { isIngredientRange, isTemperatureRange } from '@/utils';
import { MAX_RANGE_VALUES, STORAGE_PREFIX } from '@/constants';
import { cloneDeep } from 'lodash-es';
import { user } from '@/store/user/state';
import {
  CalculationProgressDto,
  ElecsisCalculationResultsDto,
} from '@/types/calculation';
import { CreateElecsisFormulationDto } from '@/types/user-formulations';
import {
  // GasPhaseVolumeUnit,
  SystemWeightUnit,
  TemperatureUnit,
  VariableKind,
} from '@/types/enums';
import { CalculationType } from '@/types/elecsis';

const LS_NAME = `${STORAGE_PREFIX}CALCULATION`;

const calculating = ref<boolean>(false);
const id = ref<string | undefined>();
const progress = ref<CalculationProgressDto | undefined>();
const result = ref<ElecsisCalculationResultsDto | undefined>();
const previousResult = ref<ElecsisCalculationResultsDto | undefined>();
const formulationOfResult = ref<CreateElecsisFormulationDto | undefined>();
const calculationType = ref<CalculationType>();

const data = ref<Omit<CreateElecsisFormulationDto, 'task' | 'wizard'>>(
  cloneDeep({
    ingredients: [],
    systemWeight: {
      value: 1000,
      unit: SystemWeightUnit.WATER_GRAMS_IN_INITIAL,
    },
    concentrationFactor: 1,
    temperature: {
      unit: user.settings.defaults.temperature.unit ?? TemperatureUnit.CELSIUS,
      valueKind: VariableKind.CONSTANT,
      value: {
        fixed: user.settings.defaults.temperature.value ?? 25,
      },
    },
    titratableAcidity: false,
    // Gas phase is not used within the calculator, yet is still here.
    // gasPhase: {
    //   volume: {
    //     unit: GasPhaseVolumeUnit.CUBIC_METER,
    //     value: 1,
    //   },
    // },
  }),
);

const hasMaximumRangeValues = computed(
  () =>
    !!data.value &&
    !!data.value.ingredients &&
    data.value.ingredients.length > 0 &&
    data.value.ingredients.filter((ingredient) => isIngredientRange(ingredient))
      .length +
      (isTemperatureRange(data.value.temperature) ? 1 : 0) >=
      (MAX_RANGE_VALUES ?? 1),
);

export const calculation = reactive({
  // State
  data,
  result,
  previousResult,
  formulationOfResult,
  calculating,
  id,
  progress,
  calculationType,

  suppressResult: false,

  // Computed
  hasMaximumRangeValues,
});

const init = () => {
  const ls = localStorage.getItem(LS_NAME);

  if (!ls) {
    return;
  }

  const data = JSON.parse(ls);

  if (data.data) calculation.data = data.data;
};

init();

watch(calculation, (current) => {
  localStorage.setItem(LS_NAME, JSON.stringify(current));
});
