import { STORAGE_PREFIX } from '@/constants';
import { CalculationType } from '@/types/elecsis';
import { ref, reactive, watch } from 'vue';

const LS_NAME = `${STORAGE_PREFIX}UI`;

export const ingredientsPaneCollapsed = ref<boolean>(false);
export const calculationType = ref<CalculationType>();

export const formulationIsDifferent = ref<boolean>(false);
export const calculateIsDifferent = ref<boolean>(false);
export const hasCalculated = ref<boolean>(false);

export const ui = reactive({
  ingredientsPaneCollapsed,
  calculationType,
});

const init = () => {
  const ls = localStorage.getItem(LS_NAME);

  if (!ls) {
    return;
  }

  const data = JSON.parse(ls);

  if (data.ingredientsPaneCollapsed)
    ui.ingredientsPaneCollapsed = data.ingredientsPaneCollapsed;
};

init();

watch(ui, (current) => {
  localStorage.setItem(LS_NAME, JSON.stringify(current));
});
