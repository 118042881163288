export interface ConfigurableWindow {
  /*
   * Specify a custom `window` instance, e.g. working with iframes or in testing environments.
   */
  window?: Window;
}

import { isClient, tryOnMounted, useEventListener } from '@vueuse/core';
import { ref, Ref } from 'vue';

export const defaultWindow = isClient ? window : undefined;

export interface VisibilityScrollTargetOptions extends ConfigurableWindow {
  scrollTarget?: Ref<Element | null | undefined>;
  space?: { x?: number; y?: number };
}

/**
 * Tracks the visibility of an element within the viewport.
 *
 * @see https://vueuse.org/useElementVisibility
 * @param element
 * @param options
 */
export function useElementVisibility(
  element: Ref<Element | null | undefined>,
  {
    window = defaultWindow,
    scrollTarget,
    space = { x: 0, y: 0 },
  }: VisibilityScrollTargetOptions = {},
) {
  const elementIsVisible = ref(false);

  const testBounding = () => {
    if (!window) return;

    const document = window.document;
    if (!element.value) {
      elementIsVisible.value = false;
    } else {
      const rect = element.value.getBoundingClientRect();

      elementIsVisible.value =
        rect.top <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        rect.bottom >= (space.y ?? 0) &&
        rect.right >= (space.x ?? 0);
    }
  };

  tryOnMounted(testBounding);

  if (window)
    tryOnMounted(() =>
      useEventListener(scrollTarget?.value || window, 'scroll', testBounding, {
        capture: false,
        passive: true,
      }),
    );

  return elementIsVisible;
}
