import {
  AmountUnit,
  MixtureUnit,
  PressureUnit,
  TemperatureUnit,
} from '@/types/enums';
import { ElecsisUserSettingDto } from './types/user-setting';

export const VUE_APP_API_ENDPOINT =
  (window as CustomWindow)?.endpointAPI ??
  process.env.VUE_APP_API_ENDPOINT ??
  '';

export const STORAGE_PREFIX = 'ELECSIS_';
export const ID_SEPARATOR = '__';

export const MAX_RANGE_VALUES = 1;
export const MIN_RANGE_STEPS = 2;
export const DEFAULT_RANGE_STEPS = 10;
export const MAX_RANGE_STEPS = 25;

export const PROGRESS_POLL_RATE = 2500; // in ms

export const DEFAULT_SETTINGS: ElecsisUserSettingDto = {
  defaults: {
    rangeSteps: DEFAULT_RANGE_STEPS,
    temperature: {
      value: 25,
      unit: TemperatureUnit.CELSIUS,
    },
    pressure: {
      value: 1,
      unit: PressureUnit.BAR,
    },
    componentAdditionalUnit: AmountUnit.GRAM,
    mixturesUnit: MixtureUnit.GRAM,
  },
  results: [],
};
