import { PressureUnit } from '@/types/enums';

export const convertPressure = ({
  from,
  to,
  amount,
}: {
  from: PressureUnit | string;
  to: PressureUnit | string;
  amount: number;
}): number => {
  let convertedToBar = 0;
  let newPressure = amount;

  switch (from) {
    case PressureUnit.MILLIBAR: {
      convertedToBar = amount / 1000;
      break;
    }
    case PressureUnit.PASCAL: {
      convertedToBar = amount / 100000;
      break;
    }
    case PressureUnit.ATMOSPHERE: {
      convertedToBar = amount * 1.013;
      break;
    }
    case PressureUnit.MILLIMETER_OF_MERCURY: {
      convertedToBar = amount / 750;
      break;
    }

    default:
    case PressureUnit.BAR: {
      convertedToBar = amount;
      break;
    }
  }

  switch (to) {
    case PressureUnit.MILLIBAR: {
      newPressure = convertedToBar * 1000;
      break;
    }
    case PressureUnit.PASCAL: {
      newPressure = convertedToBar * 100000;
      break;
    }
    case PressureUnit.ATMOSPHERE: {
      newPressure = convertedToBar / 1.013;
      break;
    }
    case PressureUnit.MILLIMETER_OF_MERCURY: {
      newPressure = convertedToBar * 750;
      break;
    }
    default:
    case PressureUnit.BAR: {
      newPressure = convertedToBar;
      break;
    }
  }

  return newPressure;
};
