import { ref, reactive, watch, computed } from 'vue';
import Cookies from 'js-cookie';
import { STORAGE_PREFIX } from '@/constants';
import { UserDto } from '@/types/user';
import { BaseService } from '@/services/base-service';

const LS_NAME = `${STORAGE_PREFIX}AUTH`;

const user = ref<UserDto>();

const isLoggedIn = computed(() => user.value?.email !== undefined);

export const auth = reactive({
  user,
  isLoggedIn,
});

const init = () => {
  const ls = localStorage.getItem(LS_NAME);

  if (!ls) {
    return;
  }

  const data = JSON.parse(ls);

  if (Cookies.get(BaseService.COOKIE_REFRESH_TOKEN) && data.user) {
    auth.user = data.user;
  }
};

init();

watch(auth, (current) => {
  localStorage.setItem(LS_NAME, JSON.stringify(current));
});
