export const tooltipOptions = {
  themes: {
    'tooltip-bounds': {
      $extend: 'tooltip',
      delay: {
        show: 0,
        hide: 0,
      },
      hideTriggers: ['hover'],
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true,
            altAxis: true,
            padding: 8,
          },
        },
      ],
    },
    input: {
      $extend: 'dropdown',
      offset: [0, 8],
      placement: 'left',
      triggers: [],
      autoHide: false,
      delay: { show: 0, hide: 0 },
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true,
            altAxis: true,
            padding: 8,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top'],
          },
        },
      ],
    },
    'input-inline-start': {
      $extend: 'input',
      placement: 'left',
    },
    'input-inline-end': {
      $extend: 'input',
      placement: 'right',
    },
    'input-error': {
      $extend: 'input',
    },
    'input-error-inline-start': {
      $extend: 'input-error',
      placement: 'left',
    },
    'input-error-inline-end': {
      $extend: 'input-error',
      placement: 'right',
    },
    'input-modal': {
      $extend: 'input',
    },
    'input-modal-inline-start': {
      $extend: 'input-modal',
      placement: 'left',
    },
    'input-modal-inline-end': {
      $extend: 'input-modal',
      placement: 'right',
    },
    'input-modal-error': {
      $extend: 'input-error',
    },
    'input-modal-error-inline-start': {
      $extend: 'input-modal',
      placement: 'left',
    },
    'input-modal-error-inline-end': {
      $extend: 'input-modal',
      placement: 'right',
    },
    'icon-dropdown': {
      $extend: 'dropdown',
    },
  },
};
