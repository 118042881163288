import {
  CreateElecsisFormulationDto,
  FormulationDto,
} from '@/types/user-formulations';
import { BaseService } from './base-service';

class FormulationService extends BaseService {
  async getFormulations() {
    return await this.get<FormulationDto[]>('user/formulations');
  }

  async createFormulation(formulation: CreateElecsisFormulationDto) {
    return await this.post<FormulationDto>('user/formulations', {
      body: JSON.stringify(formulation),
    });
  }

  async deleteFormulation(id: number) {
    return await this.delete(`user/formulations/${String(id)}`);
  }
}

export const formulationService = new FormulationService();
