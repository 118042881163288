import { miscService } from '@/services/misc-service';
import { CreateEmailDto } from '@/types/email';
import { ConfirmationDialog, misc } from './state';

export const sendEmail = async (email: CreateEmailDto) => {
  const response = await miscService.sendEmail(email);

  switch (response.status) {
    case 200:
    case 201: {
      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('sendEmail: Unhandled response', response);
      } else {
        console.error('sendEmail: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const confirmDialog = async (
  options: Omit<ConfirmationDialog, 'onOk' | 'onCancel' | 'onDismiss'>,
): Promise<boolean> => {
  return new Promise((resolve) => {
    const onOk = () => {
      resolve(true);

      onDismiss();
    };

    const onCancel = () => {
      resolve(false);

      onDismiss();
    };

    const onDismiss = () => {
      resolve(false);

      misc.showConfirmationDialog = false;
      misc.confirmationDialog = {};
    };

    misc.confirmationDialog = {
      ...options,
      onOk,
      onCancel,
      onDismiss,
    };

    misc.showConfirmationDialog = true;
  });
};
