import {
  CalculationType,
  CalculationTypeAdjustPh,
  CalculationTypeAdjustWaterActivity,
  CalculationTypeCalculateSolubility,
} from '@/types/elecsis';
import { IngredientKind } from '@/types/enums';
import {
  ComponentIngredientItem,
  IngredientItem,
  MixtureIngredientItem,
} from '@/types/ingredients';
import {
  Ingredient,
  Fixed,
  Range,
  Temperature,
  CalculatedAmount,
} from '@/types/user-formulations';

export function isComponent(
  ingredient: IngredientItem,
): ingredient is ComponentIngredientItem {
  return ingredient.ingredientKind === IngredientKind.COMPONENT;
}

export function isMixture(
  ingredient: IngredientItem,
): ingredient is MixtureIngredientItem {
  return ingredient.ingredientKind === IngredientKind.MIXTURE;
}

export function isCorbionMixture(
  ingredient: IngredientItem,
): ingredient is MixtureIngredientItem {
  return isMixture(ingredient) && ingredient.category === undefined;
}

export function isIngredientFixed(
  ingredient: Ingredient,
): ingredient is Ingredient<Fixed> {
  return (ingredient.amount.value as Fixed).fixed !== undefined;
}

export function isIngredientRange(
  ingredient: Ingredient,
): ingredient is Ingredient<Range> {
  return (ingredient.amount.value as Range).range !== undefined;
}

export function isIngredientCalculated(
  ingredient: Ingredient,
): ingredient is Ingredient<CalculatedAmount> {
  return (
    (ingredient as Ingredient<CalculatedAmount>).amount.value.calculated !==
    undefined
  );
}

export function isIngredientCalculatedFixed(
  ingredient: Ingredient,
): ingredient is Ingredient<CalculatedAmount<Fixed>> {
  return (
    (ingredient as Ingredient<CalculatedAmount<Fixed>>).amount.value.calculated
      .value.fixed !== undefined
  );
}

export function isIngredientCalculatedRange(
  ingredient: Ingredient,
): ingredient is Ingredient<CalculatedAmount<Range>> {
  return (
    (ingredient as Ingredient<CalculatedAmount<Range>>).amount.value.calculated
      .value.range !== undefined
  );
}
export function isAdjustPhCalculation(
  calculationType: CalculationType,
): calculationType is CalculationTypeAdjustPh {
  return (calculationType as CalculationTypeAdjustPh).type === 'adjust-ph';
}

export function isCalculateSolubilityCalculation(
  calculationType: CalculationType,
): calculationType is CalculationTypeCalculateSolubility {
  return (
    (calculationType as CalculationTypeCalculateSolubility).type ===
    'calculate-solubility'
  );
}

export function isAdjustAwCalculation(
  calculationType: CalculationType,
): calculationType is CalculationTypeAdjustWaterActivity {
  return (
    (calculationType as CalculationTypeAdjustWaterActivity).type ===
    'adjust-water-activity'
  );
}

export function isTemperatureFixed(
  temperature: Temperature,
): temperature is Temperature<Fixed> {
  return (temperature as Temperature<Fixed>).value.fixed !== undefined;
}

export function isTemperatureRange(
  temperature: Temperature,
): temperature is Temperature<Range> {
  return (temperature as Temperature<Range>).value.range !== undefined;
}
