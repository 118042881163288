import type { App } from 'vue';

// Atoms
import SbButton from '@/components/atoms/Button.vue';
import SbCheckbox from '@/components/atoms/Checkbox.vue';
import SbDivider from '@/components/atoms/Divider.vue';
import SbElevator from '@/components/atoms/Elevator.vue';
import SbIcon from '@/components/atoms/Icon.vue';
import SbInput from '@/components/atoms/Input.vue';
import SbLink from '@/components/atoms/Link.vue';
import SbLogo from '@/components/atoms/Logo.vue';
import SbModal from '@/components/atoms/Modal.vue';
import SbMultiselect from '@/components/atoms/Multiselect.vue';
import SbProgressBar from '@/components/atoms/ProgressBar.vue';
import SbRadio from '@/components/atoms/Radio.vue';
import SbSelect from '@/components/atoms/Select.vue';
import SbText from '@/components/atoms/Text.vue';
import SbToggle from '@/components/atoms/Toggle.vue';

// Icons
import SbSvgSymbols from '@/components/icons/SvgSymbols.vue';
import SbIconInfo from '@/components/icons/Info.vue';
import SbIconLoader from '@/components/icons/Loader.vue';

// Molecules
import SbField from '@/components/molecules/Field.vue';
import SbAccordion from '@/components/molecules/Accordion.vue';

export const registerComponents = async (app: App<Element>) => {
  // Atoms
  app.component('SbButton', SbButton);
  app.component('SbCheckbox', SbCheckbox);
  app.component('SbDivider', SbDivider);
  app.component('SbElevator', SbElevator);
  app.component('SbIcon', SbIcon);
  app.component('SbInput', SbInput);
  app.component('SbLink', SbLink);
  app.component('SbLogo', SbLogo);
  app.component('SbModal', SbModal);
  app.component('SbMultiselect', SbMultiselect);
  app.component('SbProgressBar', SbProgressBar);
  app.component('SbRadio', SbRadio);
  app.component('SbSelect', SbSelect);
  app.component('SbText', SbText);
  app.component('SbToggle', SbToggle);

  // Icon
  app.component('SbSvgSymbols', SbSvgSymbols);
  app.component('SbIconInfo', SbIconInfo);
  app.component('SbIconLoader', SbIconLoader);

  // Molecules
  app.component('SbField', SbField);
  app.component('SbAccordion', SbAccordion);
};
