import { reactive } from 'vue';

import { ui } from '@/store/ui/state';
import { onboarding } from '@/store/onboarding/state';
import { auth } from '@/store/auth/state';
import { ingredients } from '@/store/ingredients/state';
import { user } from '@/store/user/state';
import { calculation } from '@/store/calculation/state';
import { misc } from '@/store/misc/state';

export const state = reactive({
  ui,
  onboarding,
  auth,
  ingredients,
  user,
  calculation,
  misc,
});
