import { state } from '@/store';
import {
  isIngredientFixed,
  isIngredientRange,
  isSameIngredient,
  isTemperatureFixed,
  isTemperatureRange,
  round,
} from '@/utils';
import { calculation } from './state';
import { PROGRESS_POLL_RATE } from '@/constants';
import {
  AmountUnit,
  CalculationProgressStage,
  // GasPhaseVolumeUnit,
  SystemWeightUnit,
  VariableKind,
} from '@/types/enums';
import {
  CreateElecsisFormulationDto,
  Ingredient,
} from '@/types/user-formulations';
import { calculationService } from '@/services/calculation-service';
import { IngredientItem } from '@/types/ingredients';
import { formulationIsDifferent } from '../ui/state';

export const solveCalculation = async (
  apiCalculation: CreateElecsisFormulationDto,
) => {
  calculation.id = undefined;
  calculation.progress = undefined;
  calculation.result = undefined;

  // @ts-expect-error Explicitly remove any gasPhase from calculating.
  delete apiCalculation.gasPhase;

  const response = await calculationService.solveCalculation(apiCalculation);

  switch (response.status) {
    case 200:
    case 201: {
      if (response.ok) {
        calculation.calculating = true;
        calculation.id = response.data.calculationId;

        return await getProgressOnCalculation();
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('solveCalculation: Unhandled response', response);
      } else {
        console.error('solveCalculation: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const getProgressOnCalculation = async () => {
  if (!calculation.id) {
    return { success: false, error: 'No calculation' };
  }

  if (!calculation.calculating) {
    return { success: false, error: 'No longer calculating (did you cancel?)' };
  }

  const response = await calculationService.getProgress(calculation.id);

  switch (response.status) {
    case 200: {
      if (response.ok) {
        calculation.progress = response.data;

        switch (calculation.progress.stage) {
          case CalculationProgressStage.FINISHED: {
            return await getResultsById(calculation.id);
          }
          case CalculationProgressStage.WAITING:
          case CalculationProgressStage.RUNNING: {
            return new Promise(
              (
                resolve: (value: { success: boolean; error?: string }) => void,
              ) => {
                setTimeout(
                  async () => resolve(await getProgressOnCalculation()),
                  PROGRESS_POLL_RATE,
                );
              },
            );
          }
        }
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getProgressOnCalculation: Unhandled response', response);
      } else {
        console.error('getProgressOnCalculation: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const cancelCalculation = async () => {
  if (!calculation.id) {
    return { success: false, error: 'No calculation' };
  }

  const response = await calculationService.cancelCalculation(calculation.id);

  switch (response.status) {
    case 200: {
      calculation.calculating = false;
      calculation.progress = undefined;

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getResults: Unhandled response', response);
      } else {
        console.error('getResults: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const getResultsById = async (
  calculationId: string,
  type: 'json' | 'excel' = 'json',
) => {
  const response = await calculationService.resultCalculation(
    calculationId,
    type,
  );

  switch (response.status) {
    case 200: {
      if (response.ok) {
        calculation.calculating = false;
        calculation.result = response.data;

        return { success: true, data: response.data };
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getResultsById: Unhandled response', response);
      } else {
        console.error('getResultsById: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const getFormulationFromCalculation = async (calculationId: string) => {
  const response = await calculationService.getFormulationFromCalculation(
    calculationId,
  );

  switch (response.status) {
    case 200: {
      if (response.ok) {
        calculation.formulationOfResult = response.data;
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn(
          'getFormulationFromCalculation: Unhandled response',
          response,
        );
      } else {
        console.error(
          'getFormulationFromCalculation: Unhandled error',
          response,
        );
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const addIngredient = (ingredient: IngredientItem) => {
  formulationIsDifferent.value = true;

  state.calculation.data.ingredients = [
    ...state.calculation.data.ingredients,
    {
      ingredientId: ingredient.ingredientId,
      ingredientKind: ingredient.ingredientKind,
      amount: {
        unit: AmountUnit[state.user.settings.defaults.mixturesUnit],
        value: {
          fixed: 0,
        },
      },
    },
  ];
};

export const removeIngredient = (ingredient: Ingredient) => {
  formulationIsDifferent.value = true;

  state.calculation.data.ingredients =
    state.calculation.data.ingredients.filter(
      (item) => !isSameIngredient(item, ingredient),
    );
};

export const switchIngredientQuantity = (
  calculationIngredient: Ingredient,
  to: VariableKind,
) => {
  if (
    isIngredientFixed(calculationIngredient) &&
    to === VariableKind.VARIABLE
  ) {
    state.calculation.data.ingredients = state.calculation.data.ingredients.map(
      (item) => {
        if (isSameIngredient(item, calculationIngredient)) {
          return {
            ...calculationIngredient,
            amount: {
              unit: calculationIngredient.amount.unit,
              value: {
                range: {
                  from: round(calculationIngredient.amount.value.fixed),
                  to: round(calculationIngredient.amount.value.fixed + 1),
                  steps: state.user.settings.defaults.rangeSteps,
                },
              },
            },
          };
        }

        return item;
      },
    );
  } else if (
    isIngredientRange(calculationIngredient) &&
    to === VariableKind.CONSTANT
  ) {
    state.calculation.data.ingredients = state.calculation.data.ingredients.map(
      (item) => {
        if (isSameIngredient(item, calculationIngredient)) {
          return {
            ...calculationIngredient,
            amount: {
              unit: calculationIngredient.amount.unit,
              value: {
                fixed: round(calculationIngredient.amount.value.range.from),
              },
            },
          };
        }

        return item;
      },
    );
  }
};

export const switchTemperatureQuantity = (to: VariableKind) => {
  if (
    isTemperatureFixed(state.calculation.data.temperature) &&
    to === VariableKind.VARIABLE
  ) {
    state.calculation.data.temperature = {
      ...state.calculation.data.temperature,
      value: {
        range: {
          from: round(state.calculation.data.temperature.value.fixed),
          to: round(state.calculation.data.temperature.value.fixed + 1),
          steps: state.user.settings.defaults.rangeSteps,
        },
      },
    };
  } else if (
    isTemperatureRange(state.calculation.data.temperature) &&
    to === VariableKind.CONSTANT
  ) {
    state.calculation.data.temperature = {
      ...state.calculation.data.temperature,
      value: {
        fixed: round(state.calculation.data.temperature.value.range.from),
      },
    };
  }
};

export const setCalculationData = (data: CreateElecsisFormulationDto) => {
  calculation.data = data;
};

export const resetFormulation = () => {
  calculation.data = {
    ...calculation.data,
    ingredients: [],
    systemWeight: {
      value: 1000,
      unit: SystemWeightUnit.WATER_GRAMS_IN_INITIAL,
    },
  };
};

export const resetCalculation = () => {
  calculation.data = {
    ...calculation.data,
    concentrationFactor: 1,
    temperature: {
      unit: state.user.settings.defaults.temperature.unit,
      value: {
        fixed: state.user.settings.defaults.temperature.value,
      },
    },
    titratableAcidity: false,
    // Gas phase is not used within the calculator, yet is still here.
    // gasPhase: {
    //   volume: {
    //     unit: GasPhaseVolumeUnit.CUBIC_METER,
    //     value: 1,
    //   },
    // },
  };
};
