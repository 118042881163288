export enum IngredientKind {
  COMPONENT = 'COMPONENT',
  MIXTURE = 'MIXTURE',
}

export enum IngredientCategory {
  WEAK_BASE = 'ENUM_WEAK_BASE',
  WEAK_ACID = 'ENUM_WEAK_ACID',
  STRONG_BASE = 'ENUM_STRONG_BASE',
  STRONG_ACID = 'ENUM_STRONG_ACID',
  SALT = 'ENUM_SALT',
  NONELECTROLYTE = 'ENUM_NONELECTROLYTE',
  GAS = 'ENUM_GAS',
  AMPHOTER = 'ENUM_AMPHOTER',
  NULL = 'ENUM_NULL',
}

export enum TemperatureUnit {
  CELSIUS = 'DEGREES_CELSIUS',
  FAHRENHEIT = 'DEGREES_FAHRENHEIT',
  KELVIN = 'KELVIN',
}

export enum PressureUnit {
  BAR = 'BAR',
  MILLIBAR = 'MILLIBAR',
  PASCAL = 'PASCAL',
  ATMOSPHERE = 'ATMOSPHERE',
  MILLIMETER_OF_MERCURY = 'MILLIMETER_OF_MERCURY',
}

export enum AmountUnit {
  GRAM = 'GRAM',
  MILLIGRAM = 'MILLIGRAM',
  MOLE = 'MOLE',
  MILLIMOLE = 'MILLIMOLE',
  WEIGHT_PERCENTAGE = 'WEIGHT_PERCENTAGE',
  GRAM_PER_KILOGRAM_OF_TOTAL = 'GRAM_PER_KILOGRAM_OF_TOTAL',
  MILLIGRAM_PER_KILOGRAM_OF_TOTAL = 'MILLIGRAM_PER_KILOGRAM_OF_TOTAL',
  MOLE_PER_KILOGRAM_OF_TOTAL = 'MOLE_PER_KILOGRAM_OF_TOTAL',
  MILLIMOLE_PER_KILOGRAM_OF_TOTAL = 'MILLIMOLE_PER_KILOGRAM_OF_TOTAL',
  GRAM_PER_KILOGRAM_OF_WATER = 'GRAM_PER_KILOGRAM_OF_WATER',
  MILLIGRAM_PER_KILOGRAM_OF_WATER = 'MILLIGRAM_PER_KILOGRAM_OF_WATER',
  MOLE_PER_KILOGRAM_OF_WATER = 'MOLE_PER_KILOGRAM_OF_WATER',
  MILLIMOLE_PER_KILOGRAM_OF_WATER = 'MILLIMOLE_PER_KILOGRAM_OF_WATER',
}

export enum MixtureUnit {
  GRAM = 'GRAM',
  MILLIGRAM = 'MILLIGRAM',
  WEIGHT_PERCENTAGE = 'WEIGHT_PERCENTAGE',
}

export enum Salutation {
  NOT_SET = 'NOT_SET',
  MR = 'MR',
  MRS = 'MRS',
  MS = 'MS',
}

export enum JobPosition {
  PURCHASING = 'PURCHASING',
  QUALITY_CONTROL = 'QUALITY_CONTROL',
  QUALITY_ASSURANCE = 'QUALITY_ASSURANCE',
  RESEARCH_AND_DEVELOPMENT = 'RESEARCH_AND_DEVELOPMENT',
  MANUFACTURING_OR_PRODUCTION = 'MANUFACTURING_OR_PRODUCTION',
  NPF_OR_DEVELOPMENT = 'NPF_OR_DEVELOPMENT',
  MARKETING_AND_SALES = 'MARKETING_AND_SALES',
  OTHER = 'OTHER',
}

export enum CompanyType {
  SUPPLIER = 'SUPPLIER',
  MANUFACTURER = 'MANUFACTURER',
}

export enum CompanyEmployeeCount {
  NOT_SET = 'NOT_SET',
  FROM_0_TO_10 = 'FROM_0_TO_10',
  FROM_10_TO_100 = 'FROM_10_TO_100',
  FROM_100_TO_250 = 'FROM_100_TO_250',
  FROM_250_TO_1000 = 'FROM_250_TO_1000',
  MORE_THAN_1000 = 'MORE_THAN_1000',
}

export enum EventWizard {
  AW = 'AW',
  PH = 'PH',
  OSMOLALITY = 'OSMOLALITY',
  SOLUBILITY = 'SOLUBILITY',
  EXTENDED = 'EXTENDED',
}

export enum EventTask {
  CALCULATE_PH = 'CALCULATE_PH',
  TITRATE = 'TITRATE',
  CALCULATE_TITRATABLE_ACIDITY = 'CALCULATE_TITRATABLE_ACIDITY',
  ADJUST = 'ADJUST',
}

export enum SystemWeightUnit {
  WATER_GRAMS_IN_FINAL = 'WATER_GRAMS_IN_FINAL',
  WATER_GRAMS_IN_INITIAL = 'WATER_GRAMS_IN_INITIAL',
  WATER_KILOGRAMS_IN_FINAL = 'WATER_KILOGRAMS_IN_FINAL',
  WATER_KILOGRAMS_IN_INITIAL = 'WATER_KILOGRAMS_IN_INITIAL',
  TOTAL_GRAMS_OF_FINAL = 'TOTAL_GRAMS_OF_FINAL',
  TOTAL_KILOGRAMS_OF_FINAL = 'TOTAL_KILOGRAMS_OF_FINAL',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum CalculatedVariable {
  PH = 'PH',
  WATER_ACTIVITY = 'WATER_ACTIVITY',
  IONIC_STRENGTH = 'IONIC_STRENGTH',
  OSMOLALITY = 'OSMOLALITY',
  TITRATABLE_ACIDITY = 'TITRATABLE_ACIDITY',
  PRESSURE = 'PRESSURE',
  PRECIPITATION = 'PRECIPITATION',
}

export enum CalculationResultState {
  UNFINISHED = 'UNFINISHED',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  NOT_FOUND = 'NOT_FOUND',
}

export enum CalculationErrorKind {
  CALCULATION = 'CALCULATION',
  VALIDATION = 'VALIDATION',
  MODEL = 'MODEL',
  INTERNAL = 'INTERNAL',
}

export enum Phase {
  AQUEOUS = 'AQUEOUS',
  SOLID = 'SOLID',
  GAS = 'GAS',
}

export enum GasPhaseVolumeUnit {
  CUBIC_METER = 'CUBIC_METER',
  LITER = 'LITER',
  MILLILITER = 'MILLILITER',
}

export enum VariableKind {
  VARIABLE = 'VARIABLE',
  CONSTANT = 'CONSTANT',
  RANGE_PARAMETER = 'RANGE_PARAMETER',
}

export enum VariableCategory {
  INITIAL_GRAMS = 'INITIAL_GRAMS',
  INITIAL_MOLES = 'INITIAL_MOLES',
  INITIAL_WEIGHT_PERCENTAGE = 'INITIAL_WEIGHT_PERCENTAGE',
  INITIAL_PROPORTIONAL_AMOUNT = 'INITIAL_PROPORTIONAL_AMOUNT',
  EQUILIBRIUM_GRAMS = 'EQUILIBRIUM_GRAMS',
  EQUILIBRIUM_MOLES = 'EQUILIBRIUM_MOLES',
  EQUILIBRIUM_WEIGHT_PERCENTAGE = 'EQUILIBRIUM_WEIGHT_PERCENTAGE',
  EQUILIBRIUM_PROPORTIONAL_AMOUNT = 'EQUILIBRIUM_PROPORTIONAL_AMOUNT',
  MOLALITY = 'MOLALITY',
  ACTIVITY = 'ACTIVITY',
  ACTIVITY_COEFFICIENT = 'ACTIVITY_COEFFICIENT',
  MOLE_FRACTION = 'MOLE_FRACTION',
  GAS_MOLE_FRACTION = 'GAS_MOLE_FRACTION',
  MOLAR_MASS = 'MOLAR_MASS',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export enum CalculationProgressStage {
  WAITING = 'WAITING',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
}
