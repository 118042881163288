import { STORAGE_PREFIX } from '@/constants';
import Shepherd from 'shepherd.js';
import { reactive, watch } from 'vue';

const LS_NAME = `${STORAGE_PREFIX}ONBOARDING`;

export const tour = new Shepherd.Tour({
  tourName: 'Onboarding',
  confirmCancel: false,
  useModalOverlay: true,
  exitOnEsc: true,
  keyboardNavigation: true,
  classPrefix: 'sb-onboarding',
  defaultStepOptions: {
    arrow: false,
    classes: 'sb_onboarding',
    modalOverlayOpeningPadding: 4,
    modalOverlayOpeningRadius: 4,
    scrollTo: { behavior: 'smooth', block: 'nearest', inline: 'nearest' },
    cancelIcon: { enabled: true },
    popperOptions: {
      modifiers: [
        { name: 'offset', options: { offset: [0, 24] } },
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true,
            altAxis: true,
            padding: 24,
          },
        },
      ],
    },
  },
});

tour.addStep({
  id: 'step1',
  title: 'Ingredients',
  text: `In this section you can select ingredients. Press '+' to add them to your formulation.`,
  attachTo: {
    element: '.sb_pane_ingredients_inner',
    on: 'auto',
  },
  buttons: [
    {
      text: '<button class="sb_button sb_button--outline sb_button_skip">Skip</button>',
      action: tour.cancel,
    },
    {
      text: '<button class="sb_button sb_button--primary"><span>Next</span><span class="sb_icon"><svg role="img"><use xlink:href="#icon_chevron-right-squared" /></svg></span></button>',
      action: tour.next,
    },
  ],
});

tour.addStep({
  id: 'step2',
  title: 'Formulation',
  text: 'In this section the formulation is made with ingredients from the ingredients tab.  ',
  attachTo: {
    element: '.sb_pane_formulation',
    on: 'auto',
  },
  buttons: [
    {
      text: '<button class="sb_button sb_button--outline sb_button_previous">Previous</button>',
      action: tour.back,
    },
    {
      text: '<button class="sb_button sb_button--primary"><span>Next</span><span class="sb_icon"><svg role="img"><use xlink:href="#icon_chevron-right-squared" /></svg></span></button>',
      action: tour.next,
    },
  ],
});

tour.addStep({
  id: 'step3',
  title: 'Calculate',
  text: 'In this section you select the type of calculation you want to execute.',
  attachTo: {
    element: '.sb_pane_calculate_inner',
    on: 'auto',
  },
  buttons: [
    {
      text: '<button class="sb_button sb_button--outline sb_button_previous">Previous</button>',
      action: tour.back,
    },
    {
      text: '<button class="sb_button sb_button--primary"><span>Next</span><span class="sb_icon"><svg role="img"><use xlink:href="#icon_chevron-right-squared" /></svg></span></button>',
      action: tour.next,
    },
  ],
});

tour.addStep({
  id: 'step4',
  title: 'Result',
  text: 'In this section you can view the results. From here you can also apply additions to the formulation.',
  attachTo: {
    element: '.sb_pane_result_inner',
    on: 'auto',
  },
  buttons: [
    {
      text: '<button class="sb_button sb_button--outline sb_button_previous">Previous</button>',
      action: tour.back,
    },
    {
      text: '<button class="sb_button sb_button--primary"><span>Finish</span><span class="sb_icon"><svg role="img"><use xlink:href="#icon_chevron-right-squared" /></svg></span></button>',
      action: tour.complete,
    },
  ],
});

['complete', 'cancel', 'hide', 'show', 'start', 'active', 'inactive'].forEach(
  (event) =>
    tour.on(event, () => {
      switch (event) {
        case 'cancel':
        case 'complete':
          if (!onboarding.completed) {
            onboarding.completed = true;

            tour.removeStep('step0');
            tour.removeStep('step1');
            tour.addStep(
              {
                id: 'step1',
                title: 'Ingredients',
                text: `In this section you can select ingredients. Press '+' to add them to your formulation.`,
                attachTo: {
                  element: '.sb_pane_ingredients_inner',
                  on: 'auto',
                },
                buttons: [
                  {
                    text: '<button class="sb_button sb_button--outline sb_button_skip">Skip</button>',
                    action: tour.cancel,
                  },
                  {
                    text: '<button class="sb_button sb_button--primary"><span>Next</span><span class="sb_icon"><svg role="img"><use xlink:href="#icon_chevron-right-squared" /></svg></span></button>',
                    action: tour.next,
                  },
                ],
              },
              0,
            );
            tour.cancel();
          }

          onboarding.active = false;
          break;

        case 'active':
        case 'start':
        case 'show':
          onboarding.active = true;
          break;

        case 'inactive':
        case 'hide':
          onboarding.active = false;
          break;

        default:
          break;
      }
    }),
);

export const onboarding = reactive({
  completed: false,
  active: false,
});

const init = () => {
  const ls = localStorage.getItem(LS_NAME);

  if (!ls) {
    return;
  }

  const data = JSON.parse(ls);

  if (data.completed) onboarding.completed = data.completed;
};

init();

watch(onboarding, (current) => {
  localStorage.setItem(LS_NAME, JSON.stringify(current));
});
