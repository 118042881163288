import { ref, reactive, watch } from 'vue';
import { DEFAULT_SETTINGS, STORAGE_PREFIX } from '@/constants';
import { ElecsisUserSettingDto } from '@/types/user-setting';
import { FormulationDto } from '@/types/user-formulations';

const LS_NAME = `${STORAGE_PREFIX}USER`;

const settings = ref<ElecsisUserSettingDto>(DEFAULT_SETTINGS);

const formulations = ref<FormulationDto[]>([]);

export const user = reactive({
  settings,
  formulations,
});

const init = () => {
  const ls = localStorage.getItem(LS_NAME);

  if (!ls) {
    return;
  }

  const data = JSON.parse(ls);

  if (data.settings) user.settings = data.settings;
  if (data.formulations) user.formulations = data.formulations;
};

init();

watch(user, (current) => {
  localStorage.setItem(LS_NAME, JSON.stringify(current));
});
