import {
  CalculationIdDto,
  CalculationProgressDto,
  ElecsisCalculationResultsDto,
} from '@/types/calculation';
import { CreateElecsisFormulationDto } from '@/types/user-formulations';
import { BaseService } from './base-service';

class CalculationService extends BaseService {
  async solveCalculation(calculation: CreateElecsisFormulationDto) {
    return await this.post<CalculationIdDto>('elecsis/solve', {
      body: JSON.stringify(calculation),
    });
  }

  async getProgress(calculationId: string) {
    return await this.get<CalculationProgressDto>(
      `elecsis/${calculationId}/progress`,
    );
  }

  async cancelCalculation(calculationId: string) {
    return await this.put<ElecsisCalculationResultsDto>(
      `elecsis/${calculationId}/cancel`,
    );
  }

  async resultCalculation(
    calculationId: string,
    type: 'json' | 'excel' = 'json',
  ) {
    const contentTypeMap = {
      json: 'application/json',
      excel:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    const contentType = contentTypeMap[type];

    return await this.get<ElecsisCalculationResultsDto>(
      `elecsis/${calculationId}/results`,
      {
        headers: {
          'Content-Type': contentType,
        },
      },
    );
  }

  async getFormulationFromCalculation(calculationId: string) {
    return await this.get<CreateElecsisFormulationDto>(
      `elecsis/${calculationId}/formulation`,
    );
  }
}

export const calculationService = new CalculationService();
