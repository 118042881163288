import { setCalculationData } from '@/store/calculation/actions';
import { DEFAULT_SETTINGS } from '@/constants';
import { userService } from '@/services/user-service';
import { formulationService } from '@/services/formulation-service';
import { user } from './state';
import { cloneDeep } from 'lodash-es';
import { CreateElecsisUserSettingDto } from '@/types/user-setting';
import {
  CreateElecsisFormulationDto,
  FormulationDto,
} from '@/types/user-formulations';

export const getSettings = async () => {
  const response = await userService.getSettings();

  switch (response.status) {
    case 200: {
      if (response.ok) {
        user.settings = response.data;
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getSettings: Unhandled response', response);
      } else {
        console.error('getSettings: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const updateSettings = async (settings: CreateElecsisUserSettingDto) => {
  const response = await userService.putSettings(settings);

  switch (response.status) {
    case 200:
    case 201:
    case 204: {
      user.settings = settings;

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('updateSettings: Unhandled response', response);
      } else {
        console.error('updateSettings: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const resetSettings = () => {
  user.settings = DEFAULT_SETTINGS;
};

export const getFormulations = async () => {
  const response = await formulationService.getFormulations();

  switch (response.status) {
    case 200: {
      if (response.ok) {
        user.formulations = response.data;
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getFormulations: Unhandled response', response);
      } else {
        console.error('getFormulations: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const createFormulation = async (
  formulation: CreateElecsisFormulationDto,
) => {
  const formulationToSave = cloneDeep(formulation);
  formulationToSave.ingredients = formulationToSave.ingredients.map(
    (ingredient) => ({
      ingredientId: ingredient.ingredientId,
      ingredientKind: ingredient.ingredientKind,
      amount: ingredient.amount,
    }),
  );

  // @ts-expect-error Reason: force to removed formulationId
  formulationToSave.formulationId = undefined;
  // @ts-expect-error Reason: force to removed createdAt
  formulationToSave.createdAt = undefined;

  const response = await formulationService.createFormulation(
    formulationToSave,
  );

  switch (response.status) {
    case 200:
    case 201: {
      if (response.ok) {
        getFormulations();
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('createFormulation: Unhandled response', response);
      } else {
        console.error('createFormulation: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const deleteFormulation = async (formulationId: number) => {
  const response = await formulationService.deleteFormulation(formulationId);

  switch (response.status) {
    case 200:
    case 204: {
      user.formulations = user.formulations?.filter(
        (formulation) => formulation.formulationId !== formulationId,
      );

      return { success: true };
    }
    case 404: {
      return { success: false, error: 'Formulation not found' };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('deleteFormulation: Unhandled response', response);
      } else {
        console.error('deleteFormulation: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const clearFormulations = () => {
  user.formulations = [];
};

export const loadFormulation = (formulation: FormulationDto) => {
  setCalculationData(cloneDeep(formulation));
};
